<app-page-title title="optins.title.plural" [showContent]=true videoName="optins" class="space-content">
    <app-invite-link dropDownTitle="optins.invite-link.invite-employee-using-this-link"
                     dropDownSubtitle="optins.invite-link.invite-employee-using-this-link-complement"
                     dropDownSuggestion="optins.invite-link.invite-link-email-suggestion"
                     buttonTitle="optins.invite-link.button-text"
                     [generateLinkFunction]="generateOptinLink"
                     [disabled]="isViewMode && !isHelper"
                     *ngIf="hasAnyPermission('verify-employee-advice-requests') || isUserCompanyResponsible() || hasAnyHumanResourcePermission()"
                     rightContent></app-invite-link>
</app-page-title>
<div id="optins-tabs" class="space-content w=-">
    <div class="row border border-primary">
        <div class="col-12">
            <ul class="nav nav-tabs nav-pills nav-fill" role="tablist">
                <li class="nav-item active">
                    <a class="nav-link" id="link-pendent" aria-selected="false" href="#pendent" role="tab"
                       data-toggle="tab"
                       (click)="clickTab('pendent')">
                        <div class="d-row align-items-center justify-content-center">
                            <div>
                                <span>{{'optins.tabs.pendent' | translate}} {{getNewsPendentOptins()}}</span>
                                <app-speech-bubble-tab class="nav-link-bubble"
                                                       *ngIf="getNewsPendentOptins() && hasAnyPermission('verify-employee-advice-requests')"></app-speech-bubble-tab>
                            </div>
                            <app-player-button videoName="optins-pendent" backgroundColor="#375884"></app-player-button>
                        </div>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="link-approved" aria-selected="false" href="#approved" role="tab"
                       data-toggle="tab" (click)="clickTab('approved')">
                        <div class="d-row align-items-center justify-content-center">
                            <div>
                                <span>{{'optins.tabs.approved' | translate}} {{getNewsApprovedOptins()}}</span>
                                <app-speech-bubble-tab class="nav-link-bubble"
                                                       *ngIf="getNewsApprovedOptins() && hasAnyPermission('deliver-employee-master-data-to-finstein')"></app-speech-bubble-tab>
                            </div>
                            <app-player-button *ngIf="currentAccountingSystem"
                                               [videoName]="currentAccountingSystem?.type + '-explanation'"
                                               backgroundColor="#375884"></app-player-button>
                        </div>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="link-data-completed" aria-selected="false" href="#data-completed" role="tab"
                       data-toggle="tab" (click)="clickTab('data-completed')">
                       <div class="d-row align-items-center justify-content-center">
                            <div>
                                <span>{{'optins.tabs.data-completed.title' | translate}} {{getNewsDataCompletedOptins()}}</span>
                                <app-speech-bubble-tab class="nav-link-bubble"
                                               [ngStyle]="{ 'right': '20%' }"
                                               *ngIf="getNewsDataCompletedOptins() && hasAnyPermission('release-optin-to-consultancy')"></app-speech-bubble-tab>
                            </div>
                            <div class="info-container">
                                <div class="popover-arrow" id="popoverArrow"></div>
                                <span class="tab-info-icon material-icons white mt-2"
                                    #popoverHook="ngbPopover"
                                    id="releaseToCopPopover"
                                    (mouseenter)="onHover()"
                                    [ngbPopover]="releaseToCop"
                                    placement="left"
                                    popoverClass="release-tab-popover"
                                    container="body"
                                    triggers="mouseenter"
                                    autoClose="outside"
                                    >info</span>
                            </div>
                       </div>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="link-not-eligible" aria-selected="false" href="#not-eligible" role="tab"
                       data-toggle="tab" (click)="clickTab('not-eligible')">
                        <div class="d-row align-items-center justify-content-center">
                            <div>
                                <span>{{'optins.tabs.not-eligible' | translate}} {{getNewsNotEligibleOptins()}}</span>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>

            <div class="tab-content p-3">
                <div role="tabpanel" class="tab-pane fade" id="pendent"
                     [ngClass]="{disabled: !hasAnyPermission('verify-employee-advice-requests')}">
                    <app-optins-list-pendent [isViewMode]="isViewMode" #pendent></app-optins-list-pendent>
                </div>
                <div role="tabpanel" class="tab-pane fade" id="approved"
                     [ngClass]="{disabled: !hasAnyPermission('deliver-employee-master-data-to-finstein')}">
                    <app-optins-list-approved [isViewMode]="isViewMode" #approved></app-optins-list-approved>
                </div>
                <div role="tabpanel" class="tab-pane fade" id="data-completed"
                     [ngClass]="{disabled: !hasAnyPermission('release-optin-to-consultancy')}">
                    <app-optins-list-data-completed [isViewMode]="isViewMode" #dataCompleted></app-optins-list-data-completed>
                </div>
                <div role="tabpanel" class="tab-pane fade" id="not-eligible"
                     [ngClass]="{disabled: !hasAnyPermission('deliver-employee-master-data-to-finstein')}">
                    <app-optins-list-not-eligible [isViewMode]="isViewMode" #notEligible></app-optins-list-not-eligible>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #releaseToCop>
    <div class="cop-popover-container">
        <div class="popover-header p-4">
            <span class='material-icons white pt-1'>info</span>
            <p class="pl-4" translate>optins.tabs.data-completed.tooltip.title</p>
        </div>
        <div class="popover-content">
            <div class="email-content-icon mr-4">
                <span class="material-icons white">
                    person
                </span>
            </div>
            <div class="email-content">
                <div class="popover-email-title d-flex">
                    <p class="title" translate>optins.tabs.data-completed.tooltip.email-template.regarding</p>
                    <p class="email-text" translate>optins.tabs.data-completed.tooltip.email-template.personal-access-data</p>
                </div>
                <div class="popover-email-text">
                    <div class="d-flex">
                        <p class="title mb-0" translate>optins.tabs.data-completed.tooltip.email-template.email</p>
                        <p class="email-text mb-0" translate>optins.tabs.data-completed.tooltip.email-template.text-1</p>
                    </div>
                    <p class="email-text" translate>optins.tabs.data-completed.tooltip.email-template.text-2</p>
                    <p class="email-text" [innerHTML]="'optins.tabs.data-completed.tooltip.email-template.text-3' | translate"></p>
                    <ul class="mb-0">
                        <li class="email-and-password">
                            <p class="email-text mr-1 mb-0">{{'common.email' | translate}}:</p>
                            <p class="email-text mb-0">
                                maxmuster&#64;gmail.com
                            </p>
                        </li>
                        <li class="email-and-password">
                            <p class="email-text mr-1" translate>{{'authentication.password' | translate}}:</p>
                            <p class="email-text">
                                xXzZyY
                            </p>
                        </li>
                    </ul>
                    <p class="d-flex email-text" [innerHTML]="getTooltipCopLink()"></p>
                    <p class="email-text" [innerHTML]="'optins.tabs.data-completed.tooltip.email-template.text-5' | translate"></p>
                    <p class="email-text" [innerHTML]="'optins.tabs.data-completed.tooltip.email-template.text-6' | translate"></p>
                    <p class="email-text ml-6" translate>optins.tabs.data-completed.tooltip.email-template.text-7</p>
                    <p class="email-text ml-6" translate>optins.tabs.data-completed.tooltip.email-template.text-8</p>
                    <p class="email-text custom-text" [innerHTML]="getScheduleSupportAppointmentLink()"></p>
                    <p class="email-text mb-0" translate>optins.tabs.data-completed.tooltip.email-template.text-10</p>
                    <p class="email-text" translate>optins.tabs.data-completed.tooltip.email-template.text-11</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
